// @ts-strict-ignore
import { faSync } from "@fortawesome/pro-regular-svg-icons";
import type { ButtonHTMLAttributes } from "react";

import type { AviarySize } from "@aviary/types";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import { NoStyleButton } from "../../Button/NoStyleButton";

import * as styles from "./DeprecatedToggle.styles";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Optionally hide/show the loading icon
   *
   * @default false
   */
  isLoading?: boolean;
  /**
   * Pass in if the toggle is "On" or "Checked"
   *
   * @default false
   */
  isOn?: boolean;
  /**
   * Optionally control the size of the toggle
   *
   * @default normal
   */
  size?: Extract<AviarySize, "small" | "normal">;
  /**
   * Override the "On" label for the toggle
   *
   * @default "On"
   */
  onLabel?: string;
  /**
   * Override the "Off" label for the toggle
   *
   * @default "Off"
   */
  offLabel?: string;
}

const DeprecatedToggle = ({
  isLoading,
  isOn,
  size = "normal",
  disabled,
  onLabel = "On",
  offLabel = "Off",
  onClick,
  ...rest
}: Props) => {
  const getTrackStyle = [
    styles.track.base,
    styles.track[size],
    (!isOn || disabled) && styles.track.isOff,
    !isLoading && !disabled && isOn && styles.track.hasHoverStyle,
    disabled && styles.track.isDisabled,
  ];

  const getBallStyle = [
    styles.ball.base,
    styles.ball[size],
    isOn && styles.ball.isOn,
    disabled && styles.ball.isDisabled,
  ];

  const getToggleStyle = [styles.toggle.base, styles.toggle[size]];

  const getToggleWrapperStyle = [
    styles.toggleWrapper.base,
    styles.toggleWrapper[size],
    disabled && styles.toggleWrapper.isDisabled,
    isLoading && styles.toggleWrapper.isLoading,
  ];

  const getImageSize = size === "small" ? "sm" : "1x";

  const renderLabel = () => {
    if (isLoading) {
      return (
        <div data-testid="toggle-icon" css={styles.label.toggleIcon}>
          <FontAwesomeIcon size={getImageSize} icon={faSync} spin />
        </div>
      );
    }

    return <label css={styles.label.text}>{isOn ? onLabel : offLabel}</label>;
  };

  const handleClick = event => {
    if (!isLoading && !disabled) {
      onClick(event);
    }
  };

  const isOnStringified = isOn ? "true" : "false";

  return (
    <NoStyleButton
      data-testid="toggle"
      css={getToggleWrapperStyle}
      onClick={handleClick}
      aria-checked={isOnStringified}
      role="switch"
      {...rest}
    >
      <div css={getToggleStyle}>
        <div css={getTrackStyle} />
        <div css={getBallStyle} />
      </div>
      {renderLabel()}
    </NoStyleButton>
  );
};
export { DeprecatedToggle };
