import type { SerializedStyles } from "@emotion/serialize";
import type { JSX, ReactNode } from "react";
import { Children, isValidElement, cloneElement, useEffect, useRef, useState } from "react";

import * as styles from "./Headers.styles";

interface Props {
  /**
   * Reduces the margin below the headers when used in smaller spacing situation
   *
   * @default false
   */
  isReducedMargin?: boolean;
  /**
   * Current open tab id, used for styling and conditional opening
   *
   * @borrows This value is passed in from <Tabs>
   */
  openTabId?: string;
  /**
   * Makes the TabHeader container go full width
   *
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * Pass down custom styles to the header wrapper
   */
  customHeadersStyle?: SerializedStyles;
  /**
   * PASSING DOWN CSS WITH THIS METHOD IS NOT ALLOWED
   *
   * IT WILL BREAK THE FUNCTIONALITY
   */
  css?: boolean;
  /**
   * DO NOT USE THIS PROP. IT IS INTERNAL ONLY
   * Callback for changing the targetTabId.
   *
   * @borrows This value is passed in from <Tabs>
   */
  // onTriggerClicked?: (tabId: string) => void;
  /**
   * A collection of TabHeader components
   *
   * @default undefined
   */
  children?: ReactNode;
}

const Headers = ({
  openTabId,
  isFullWidth = false,
  isReducedMargin,
  customHeadersStyle,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onTriggerClicked,
  children,
  ...rest
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const [showLeftShadow, setShowLeftShadow] = useState(false);

  const handleScroll = () => {
    if (!scrollRef.current) return;

    const { scrollWidth, scrollLeft, clientWidth } = scrollRef.current;

    setShowLeftShadow(scrollLeft > 0);
    setShowRightShadow(scrollWidth > scrollLeft + clientWidth);
  };

  useEffect(() => {
    handleScroll();
  }, []);

  const wrapperStyles = [
    styles.wrapper,
    showRightShadow && styles.showRightShadow,
    showLeftShadow && styles.showLeftShadow,
    isFullWidth && styles.fullwidth,
    isReducedMargin && styles.reducedMargin,
    customHeadersStyle,
  ];

  const tabHeadingStyles = [styles.tabHeaders];

  const cloneItem = (child: JSX.Element) => {
    // Only pass openTabId if child actually has a tabId
    if (child.props.tabId) {
      return cloneElement(child, {
        openTabId,
        onTriggerClicked,
        ...child.props,
      });
    }

    return child;
  };

  const renderChildren = () => {
    return Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneItem(child);
      }
    });
  };

  return (
    <div css={wrapperStyles}>
      <div role="tablist" css={tabHeadingStyles} {...rest} ref={scrollRef} onScroll={handleScroll}>
        {renderChildren()}
      </div>
    </div>
  );
};

Headers.displayName = "Tabs.Headers";

export { Headers };
